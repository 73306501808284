import React from "react";


const About = () => {
  return (
    <div className="centerCol">
      <div className="welcomeText">
        <h2>About Us</h2>
        <p>
          {" "}
          CambodiaeVisa.org (eTourist Services Pvt. Ltd.) is a service provider who specialize in facilitating/consultancy for ETA services for International travelers to Cambodia. We apply for eVisa on behalf of the International visitors. The headquarters of our organization is based in Delhi. CambodiaeVisa.org is a commercial ‘for profit’ organization which provides eVisa services after charging a service fees. The fees include the visa fees charged by the Government of Cambodia.
        </p>

        <p>
          {" "}
          It's also noteworthy that our organization is in no way affiliated to the Government of Cambodia. The Government of Cambodia is the sole issuing authority of the eVisa. We are private service providers and the applicants who wish to apply for eVisa directly can visit the Government of Cambodia operated sites in order to avail electronic visa facilities without paying any additional service charges.
        </p>

        <p>
          {" "}
          Our organization is comprised of a team of handpicked professionals who expertise in facilitating eVisa services. The team is committed towards its motto of enabling International tourists to catch a glimpse of the age old Cambodia culture and history. The queries of International tourists are answered promptly by our expert team who leave no stone unturned to provide quality services to our valuable clients. We ensure that the privacy of applicants is protected at all costs and the services we provide remain transparent. This transparency is reflected by our initiative to mail the eVisa rejection proof that we provide unconditionally to the applicants whose visa could not be approved by the officials of the Government of Cambodia. We don’t provide the applicant’s details to any third party or organization and all the information we collect is strictly for the purpose of ensuring visa approval for the applicants.
        </p>
      </div>



    </div>
  );
};

export default About;
