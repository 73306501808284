import React from "react";

const Contact = () => {
  return (
    <div className="centerCol">
      <div className="welcomeText">
        <h2>Contact Us</h2>
        <p> You can also send us an email to: info@evisacambodia.org</p>
      </div>
    </div>
  );
};

export default Contact;
