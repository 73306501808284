import React from "react";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Circles } from "react-loader-spinner";

const Loader = ({ visibility }) => {
  return (
    <>
      {visibility && (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100vh",
            position: "fixed",
            background: "rgb(255 255 255 / 75%)",
            zIndex: 99,
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Circles color="#396b8e" height={80} width={80} />
        </div>
      )}
    </>
  );
};

export default Loader;
