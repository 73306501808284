import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Table from "react-bootstrap/Table";
import Loader from "../components/Loader";
import { payment } from "../components/payment";
import { PREVIEW_APPLICATION } from "../constants/api";
import { commonApiCall } from "../services";
// import paypalPay from "../images/payalBtn.png";
import Form from "react-bootstrap/Form";
import razorPay from "../images/razorpay.png";
import PayPalGateway from "../components/paypal";
import moment from "moment";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const StepSecond = () => {
  const temporaryId = new URLSearchParams(window.location.search).get(
    "session"
  );
  const [paymentParams, setPaymentParams] = useState(null);
  const [showPayment, setShowpayment] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  // const [upLoadFile, setUpLoadFile] = useState();
  const [formData2, setFormData2] = useState({});
  const [checked, setchecked] = useState(false);
  const [selected, setSelected] = useState("normal");
  const navigate = useNavigate();
  const backAndEdit = () => {
    navigate("/visa/apply?session=" + temporaryId);
  };
  const getPreviewApplication = async () => {
    setLoader(true);
    try {
      const response = await commonApiCall(PREVIEW_APPLICATION, "post", {
        visa: window.atob(temporaryId),
      });

      if (response.status === 200) {
        setFormData(response.data);
        setFormData2(response.data);
          getVisaFees(selected, response.data);
      }
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  };

  useEffect(() => {
    getPreviewApplication();
  
  }, [temporaryId]);

  const getVisaFees = async (priority ,data) => {
    try {
      const response = await commonApiCall("/visa/fee", "post", {
        country_name: data.country_nationality,
        visa_type: data.visa_type,
        visa_priority:priority,
      });
      if (response.status === 200) {
        data.amount = response.data;
        setPaymentParams(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isButtonSelected = (value) => {
    if (selected === value) {
      return true;
    }
  };

  const handleChange = (e) => {
    setSelected(e.target.value);
    // getVisaFees(e.target.value,formData2)
  };
  const visaPriority = async () => {
    try {
      const response = await commonApiCall("/passport/visa_priority", "post", {
        tran_id: formData.tran_id,
        visa_priority: selected,
      });
      if (response.status === 200) {
        getVisaFees(selected,formData2);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const makePayment = () => {
    try {
      payment.displayRazorpay(paymentParams, (data) => {
        navigate("/visa/thank-you", {
          state: { data, paymentParams },
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {Object.keys(formData).length === 0 || loader ? (
        <Loader visibility={loader} />
      ) : (
        <div className="main-preview mb-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card h-100">
                  <div className="card-header fw-bold">
                    Personal Information
                  </div>
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="form-group me-3">
                        <label className="mb-3">
                          Application Photo<sup className="text-danger">*</sup>
                        </label>
                        <p>
                          <img
                            style={{
                              width: 150,
                              height: 185,
                              objectFit: "cover",
                              border: "1px solid #dfdfdf",
                            }}
                            src={
                              process.env.REACT_APP_BASE_URL +
                              "/files/" +
                              formData?.application_photo
                            }
                            alt="passport_img"
                          />
                        </p>
                      </div>
                      <div className="form-group">
                        {/* <label className="mb-3">
                          Passport Photo<sup className="text-danger">*</sup>
                        </label> */}
                        {/* <p>
                          <img
                            style={{
                              width: 330,
                              height: 185,
                              objectFit: "cover",
                              border: "1px solid #dfdfdf",
                            }}
                            src={
                              process.env.REACT_APP_BASE_URL +
                              "/files/" +
                              formData?.passport_image
                            }
                          />
                        </p> */}
                      </div>
                    </div>

                    {/* <div className="form-group p-2">
                      <label>
                        Application Id <sup className="text-danger">* </sup> :
                      </label>{" "}
                      {formData?.temporary_id}
                    </div> */}

                    <div className="form-group p-2">
                      <label>
                        Application Id <sup className="text-danger">*</sup> :
                      </label>{" "}
                      {formData?.temporary_id}
                    </div>
                    <div className="form-group p-2">
                      <label>
                        Surname<sup className="text-danger">*</sup> :
                      </label>{" "}
                      {formData?.surname.toUpperCase()}
                    </div>
                    <div className="form-group p-2">
                      <label>
                        Given Name<sup className="text-danger">*</sup> :
                      </label>{" "}
                      {formData?.given_name.toUpperCase()}
                    </div>
                    <div className="form-group p-2">
                      <label>
                        Email<sup className="text-danger">*</sup> :
                      </label>{" "}
                      {formData?.email.toUpperCase()}
                    </div>
                    <div className="form-group p-2">
                      <label>
                        Telephone (Telephone number in Cambodia)
                        <sup className="text-danger">*</sup> :
                      </label>{" "}
                      {formData?.telephone}
                    </div>
                    <div className="form-group p-2">
                      <label>
                        Gender<sup className="text-danger">*</sup> :
                      </label>{" "}
                      {formData?.gender.toUpperCase()}
                    </div>
                    <div className="form-group p-2">
                      <label>
                        Date of Birth<sup className="text-danger">*</sup> :
                      </label>{" "}
                      {moment(formData?.dob).format("DD/MM/YYYY")}
                    </div>
                    <div className="form-group p-2">
                      <label>
                        Country / Region of Birth
                        <sup className="text-danger">*</sup> :
                      </label>{" "}
                      {formData?.country_birth.toUpperCase()}
                    </div>
                    <div className="form-group p-2">
                      <label>
                        Country of Nationality
                        <sup className="text-danger">*</sup> :
                      </label>{" "}
                      {formData?.country_nationality.toUpperCase()}
                    </div>
                    <div className="form-group p-2">
                      <label>
                        Residential Address<sup className="text-danger">*</sup>{" "}
                        :
                      </label>{" "}
                      {formData?.residential_address.toUpperCase()}
                    </div>
                    <div className="form-group p-2">
                      <label>
                        State / Province<sup className="text-danger">*</sup> :
                      </label>{" "}
                      {formData?.state.toUpperCase()}
                    </div>
                    <div className="form-group p-2">
                      <label>
                        Address During Visit (Address in Cambodia)
                        <sup className="text-danger">*</sup> :
                      </label>{" "}
                      {formData?.address_visit.toUpperCase()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card h-100">
                  <div className="card-header fw-bold">Passport Details</div>
                  <div className="card-body">
                    <div className="form-group">
                      <label className="mb-3">
                        Passport Photo<sup className="text-danger">*</sup>
                      </label>
                      <p>
                        <img
                          style={{
                            width: 330,
                            height: 185,
                            objectFit: "cover",
                            border: "1px solid #dfdfdf",
                          }}
                          src={
                            process.env.REACT_APP_BASE_URL +
                            "/files/" +
                            formData?.passport_image
                          }
                          alt="passports"
                        />
                      </p>
                    </div>

                    <div className="form-group p-2">
                      <label>Passport Number</label> : {formData?.passport_no}
                    </div>
                    <div className="form-group p-2">
                      <label>Passport Date of Issue</label> :{" "}
                      {moment(formData?.passport_date_issue).format(
                        "DD/MM/YYYY"
                      )}
                    </div>
                    <div className="form-group p-2">
                      <label>Passport Date of Expire</label> :{" "}
                      {moment(formData?.passport_date_expiry).format(
                        "DD/MM/YYYY"
                      )}
                    </div>
                    <div className="form-group p-2">
                      <label>Country of Passport</label> :{" "}
                      {formData?.country_of_passport.toUpperCase()}
                    </div>
                    <div className="form-group p-2">
                      <label> Visa Type</label> :{" "}
                      {formData?.visa_type.toUpperCase()}
                    </div>
                    {formData.visa_type === "Visa E (Business)" && (
                      <div className="form-group p-2">
                        <label> Entry purpose</label>{" "}
                        {formData2?.entry_purpose.toUpperCase()}
                      </div>
                    )}

                    {formData.visa_type === "Visa E (Business)" && (
                      <div className="form-group p-2">
                        <label> Other Purpose : </label>
                        {formData2?.other_purpose.toUpperCase()}
                      </div>
                    )}

                    {formData.visa_type === "Visa E (Business)" && (
                      <div className="form-group p-2">
                        <label> Letter Of Invitation </label> :{" "}
                        <p className="green">
                          {" "}
                          {formData2?.letter_of_invitation
                            ? "Document Uploaded"
                            : "Not Upload"}{" "}
                        </p>
                        {/* <a
                          href={
                            process.env.REACT_APP_BASE_URL +
                            "/files/" +
                            formData?.letter_of_invitation
                          }
                          target="_blank"
                        >
                          view
                        </a> */}
                      </div>
                    )}
                    {/* <div className="form-group p-2">
                      <label> 2. Vaccine Card / Certificate</label> :{" "}
                      {formData?.vaccine_card}{" "} */}
                    {/* <a
                          href={
                            process.env.REACT_APP_BASE_URL +
                            "/files/" +
                            formData?.vaccine_card
                          }
                          target="_blank"
                        >
                          view
                        </a> */}
                    {/* </div> */}
                    <div className="form-group p-2">
                      <label> Hotel Reservation Document(s)</label> :{" "}
                      <p className="green">
                        {" "}
                        {formData?.hotel_reservation
                          ? "Document Uploaded"
                          : "Not Upload"}{" "}
                      </p>
                      {/* <a
                          href={
                            process.env.REACT_APP_BASE_URL +
                            "/files/" +
                            formData?.hotel_reservation
                          }
                          target="_blank"
                        >
                          view
                        </a> */}
                    </div>
                    <div className="form-group p-2">
                      <label> Other(s) : </label>
                      <p className="green">
                        {" "}
                        {formData?.other_document
                          ? " Document Uploaded"
                          : "Not Upload"}{" "}
                      </p>
                      {formData.other_document === " " && (
                        <div className="form-group p-2">
                          <label> Entry purpose : </label>{" "}
                          {formData2?.entry_purpose.toUpperCase()}
                        </div>
                      )}

                      {/* <a
                          href={
                            process.env.REACT_APP_BASE_URL +
                            "/files/" +
                            formData?.other_document
                          }
                          target="_blank"
                        >
                          view
                        </a> */}
                    </div>
                    <div className="form-group p-2">
                      <label> Purpose</label> :{" "}
                      {formData?.Purpose.toUpperCase()}
                    </div>
                    <div className="form-group p-2">
                      <label> Intended Date of Entry</label> :{" "}
                      {moment(formData?.intended_date_of_entry).format(
                        "DD/MM/YYYY"
                      )}
                    </div>
                    <div className="form-group p-2">
                      <label> Port of Entry</label> :{" "}
                      {formData?.port_of_entry.toUpperCase()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Container fluid>
            <Row>
              <Col className=" undertaking p-3 d-flex justify-content-around">
                <div className=" checkboxf">
                  <Form.Check
                    type="radio"
                    name="visa_fee"
                    value="normal"
                    className="kkktt"
                    checked={isButtonSelected("normal")}
                    onClick={handleChange}
                  />
                  <span>
                    {" "}
                    <b>Normal visa </b> - processing within 3-5 Days
                  </span>
                </div>
                <div className=" checkboxf">
                  <Form.Check
                    type="radio"
                    name="visa_fee"
                    value="urgent"
                    className="kkktt"
                    checked={isButtonSelected("urgent")}
                    onClick={handleChange}
                  />{" "}
                  {""}
                  <span>
                    {" "}
                    <b> Urgent visa </b> - processing within 2-5 Days
                  </span>
                </div>
              </Col>
            </Row>
          </Container>

          <Container fluid>
            <Row>
              <Col className=" undertaking p-3">
                <h5 id="undertaking_heading" className="mb-3 rounded-3">
                  Undertaking For Combodia Visa
                </h5>
                <p className="d-flex gap-2">
                  <Form.Check
                    type="checkbox"
                    checked={checked}
                    onChange={() => setchecked(!checked)}
                  />
                  {/* <input type="checkbox" onClick={(e)=>console.log(e.target.value)} /> */}
                  <span>
                    I, the applicant, hereby certify that I agree to all the "{" "}
                    <b>Terms and Conditions </b>" given on the website
                    combodiaevisa.org and understand all the questions and
                    statements of this application. The answers and information
                    furnished in this application are true and correct to the
                    best of my knowledge and belief. I understand and agree that
                    once the fee is paid towards the Temporary application ID{" "}
                    {formData?.temporary_id} is 100% non-refundable and I will
                    not claim a refund or dispute the transaction incase of
                    cancellation request raised at my end. I also understand
                    that combodiaevisa.org is only responsible for processing my
                    application and the e-Visa may be granted or rejected by the
                    Combodia government. I authorized them to take the payment
                    from my card online.
                  </span>
                </p>
              </Col>
            </Row>
          </Container>

          <div className="needbox">
            <div className="w-100 floatL m-top_20">
              {!showPayment && (
                <button
                  onClick={backAndEdit}
                  type="button"
                  className="btn-input"
                >
                  Edit
                </button>
              )}

              {!showPayment && (
                <button
                  disabled={loader}
                  type="button"
                  onClick={() =>
                    {
                      if(checked){
                        setShowpayment(true);
                        visaPriority();
                        
                      }else{
                        alert("Plese check undertaking ")
                      }
                    }
                  }
                  className="btn-input"
                >
                  Save & Pay
                </button>
              )}
              {showPayment && (
                <div className="pay-now d-flex align-items-center justify-content-center mt-5">
                  <div className="form-group me-5">
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={makePayment}
                    >
                      {" "}
                      <img
                        src={razorPay}
                        style={{
                          width: 150,
                          height: 70,
                          objectFit: "cover",
                        }}
                        alt="pay_img"
                      />
                    </button>
                  </div>
                  <div className="form-group">
                    <PayPalGateway
                      payment={paymentParams}
                      callback={(data) => {
                        navigate("/visa/thank-you", {
                          state: { data, paymentParams },
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default StepSecond;
