import axios from "axios";
import { commonApiCall } from "../services";

export const payment = {
  displayRazorpay,
};

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const __DEV__ = window.location === "localhost";

async function displayRazorpay(params, callback) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
console.log(res);
  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
console.log(params)
  const data = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/payment/razorpay`, //${config.baseUrl}RazorPay/RazorPayPaymentTicket
    data: params,
  })
  // .then((t) => t.data); 
  console.log(data,"---d-d-d-d-dd-")
  const options = {
    key: __DEV__
      ? process.env.REACT_APP_PAYMENT_API_KEY
      : process.env.REACT_APP_PAYMENT_API_KEY, //process.env.PRODUCTION_KEY,
    currency: data.data.currency, //data.currency
    amount:parseInt(data.data.amount),
    // amount:parseInt(data.data.amount),
    order_id: data.data.id,
    name: "Cambodia Visa",
    description: params.temporary_id,
    image: "",
    handler: async function (response) {
      console.log("response", response);
      // const param = {
      //   order_id: params.receipt,
      //   status: "success",
      //   payment_id: response.razorpay_payment_id,
      //   order_status: "Confirmed",
      //   amount: data.amount.toString(),
      //   email: params.email,
      // };
      let param;
      if (
        typeof response.razorpay_payment_id == "undefined" ||
        response.razorpay_payment_id < 1
      ) {
        param = {
          order_id: data.id,
          status: null,
          payment_id: response.error.metadata.payment_id,
          order_status: "Failed",
          tran_id: null,
          temporary_id: params.temporary_id,
          pay_id: response.error.metadata.payment_id,
          state: null,
          cart: null,
          payment_method: "razorpay",
          payment_status: response.error.reason,
          amount:parseInt(data.data.amount / 100),
          currency: data.currency,
          description: null,
          invoice_number: null,
          payer_email: null,
          payer_name: params.name,
          create_time: null,
          ep_tran_id: null,
          passport_no: params.passport_no,
          ip_address:data.data.ip_address,
          country_nationality:data.data.country_nationality,
          intended_date_of_entry:data.data.intended_date_of_entry,
          visa_type:data.data.visa_type
        };
      } else {
        param = {
          // order_id: params.receipt,
          // status: "success",
          // payment_id: response.razorpay_payment_id,
          // order_status: "Confirmed",
          // amount: (data.amount / 100).toString(),
          // email: params.email,
          passport_no: params.passport_no,
          order_id: data.id,
          status: null,
          payment_id: response.razorpay_payment_id,
          order_status: "Confirmed",
          tran_id: null,
          temporary_id: params.temporary_id,
          pay_id: response.razorpay_payment_id,
          state: null,
          cart: null,
          payment_method: "razorpay",
          payment_status: "success",
          amount: parseInt(data.data.amount / 100),
          currency: data.currency,
          description: null,
          invoice_number: null,
          payer_email: params.email,
          payer_name: params.surname,
          create_time: null,
          ep_tran_id: null,
          ip_address:data.data.ip_address,
          country_nationality:data.data.country_nationality,
          intended_date_of_entry:data.data.intended_date_of_entry,
          visa_type:data.data.visa_type
        };
      }
      let statusResponse = await commonApiCall(
        "/payment/updatePayment",
        "post",
        param
      );
      if (statusResponse.status === 200) {
        if (typeof callback === "function") {
          callback(param);
        }
      } else {
        // showErrorToast(statusResponse.data.msg);
      }
    },
    modal: {
      ondismiss: function () {
        let failed = {
          passport_no: params.passport_no,
          order_id: data.id,
          status: null,
          payment_id: null,
          order_status: "Failed",
          tran_id: null,
          temporary_id: params.temporary_id,
          pay_id: null,
          state: null,
          cart: null,
          payment_method: "razorpay",
          payment_status: "failed",
          amount: (data.amount / 100).toString(),
          currency: data.currency,
          description: null,
          invoice_number: null,
          payer_email: null,
          payer_name: params.surname,
          create_time: null,
          ep_tran_id: null,
        };
        // callback(failed);
      },
    },
    prefill: {
      name: localStorage.getItem("customer_name"),
      email: localStorage.getItem("email"),
      phone_number: localStorage.getItem("mobile"),
    },
  };
  const paymentObject = new window.Razorpay(options);

  paymentObject.on("payment.failed", async function (response) {
    // if (response) {
    //   const param = {
    //     order_id: data.id,
    //     status: response.error.reason,
    //     payment_id: response.error.metadata.payment_id,
    //     currency: data.currency,
    //     payer_name: params.name,
    //   };
    //   let response = await commonApiCall(
    //     "/payment/updatePayment",
    //     "post",
    //     param
    //   );
    //   if (response.status === 200) {
    //     if (typeof callback === "function") {
    //       callback(param);
    //     }
    //   } else {
    //     // showErrorToast(response.data.msg);
    //   }
    // }
  });
  paymentObject.open();
}
