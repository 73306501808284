import React from "react";
import img1 from "../images/card-img1.png";
import img2 from "../images/card-img2.png";
import img3 from "../images/card-img3.png";
import img4 from "../images/hdfc.png"
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="servicesBox">
        <div className="centerCol">
          <div className="leftside">
            <h4>Disclaimer:</h4>
            <p>
            Cambodiaevisa.org is a Non-government / Non-official website (operated by a private agency) offering e-visa services for travelers to Cambodia with a fee. While we make every effort to ensure the information is accurate and up to date, there may be changes of which we are unaware.
            </p>
          </div>
          <div className="centerside">
            <h4>Payment Option</h4>

            <div className="">
            <ul className="list-unstyled d-flex">
              <li>
                <Link to="#">
                  {" "}
                  <img src={img1} alt="" />{" "}
                </Link>
              </li>
              <li>
                <Link to="#">
                  {" "}
                  <img src={img2} alt="" />{" "}
                </Link>
              </li>
              <li>
                <Link to="#">
                  {" "}
                  <img src={img3} alt="" />{" "}
                </Link>
              </li>
              <li>
                <Link to="#">
                  {" "}
                  <img src={img4} alt="" />{" "}
                </Link>
              </li>
            </ul>
          </div>

           
          </div>

         
        </div>
      </div>

      <div className="footerBox">
        <div className="centerCol">
           <ul className="list-unstyled">
              <li>
                <Link to="/" className="bgblack1">Home</Link>
              </li>
              <li>
                <Link to="/about" className="bgblack1">About Us</Link>
              </li>
              <li>
                <Link to="#" className="bgblack1">Application</Link>
              </li>
              <li>
                <Link to="#" className="bgblack1">Latest News</Link>
              </li>
              <li>
                <Link to="#" className="bgblack1">Check & Change</Link>
              </li>
              <li>
                <Link to="/contact" className="bgblack1">Contact Us</Link>
              </li>
              <li>
                <Link to="/faq" className="bgblack1">FAQ</Link>
              </li>
              <li>
                <Link to="#" className="bgblack1">Live Chat</Link>
              </li>
            </ul>

          <div className="copyright">
            © Copyrights 2022 at e-visa Services. All Rights Reserved
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
