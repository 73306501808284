import React from "react";

const Welcomw = () => {
  return (
    <div className="centerCol">
      <div className="welcomeText">
        <h2>Welcome to Cambodia</h2>
 
        <p>
          {" "}
          <b>Electronic Travel Authorization</b> is an <b>eVisa for Cambodia</b> that allows
          travelers to enter the country. 100+ countries can avail of Cambodia
          Evisa services. This electronic visa can be obtained online and is
          easily available to the customer in PDF format. You just have to show
          a printed copy of the Evisa, once you reach Cambodia and get the
          passport stamped.
        </p>

        <p>
          {" "}
          There are two types of Evisa that the <b>Cambodian Government</b> offers to
          travelers: Tourist Visa and Business Visa. The nationality of the
          applicant decides the visa type, its duration, and the number of
          entries a traveler can do. It is better to obtain the Cambodia Evisa
          before traveling to the country. The complete process of obtaining the
          Cambodia Evisa is online and no paperwork has to be done. Once the
          application is done, the approved Evisa is sent to the registered
          email id. The travelers can carry their approved evisa printed copy
          and their passport along with them to present at the airport to get
          verified and get their passport stamped.
        </p>

        <h2>TOURIST VISA</h2>
        <p>
          {" "}
          Travelers can apply for Cambodia Evisa for tourism purposes. The
          process is easy and simple to obtain the Electronic Travel Visa for
          Cambodia. It allows a traveler to stay for 30 days maximum with a
          single entry and the Evisa stays valid for only 90 days. The approved
          ETA is received by the applicant within 2-3 days of applying. The
          applicant must have a 6 months valid passport from the date of arrival
          in the country.
        </p>
        <h2>BUSINESS VISA</h2>

        <p>
          {" "}
          Travelers can apply for Cambodia Evisa for Business purposes. The
          process is easy and simple to obtain the Electronic Travel Visa for
          Cambodia. It allows a traveler to stay for 30 days maximum with a
          single entry and the Evisa stays valid for only 180 days. The approved
          Cambodia ETA is received by the applicant within 2-3 days of applying.
          The applicant must have a 6 months valid passport from the date of
          arrival in the country.
        </p>

        <h2>Important information and links</h2>
        <ul>
          <li>
            {" "}
            • The approved Evisa should have the same information present on the
            passport. Once the Cambodia Evisa is approved verify all the
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; {"      "} information.{" "}
          </li>
          <li>
            {" "}
            • Along with the passport, don’t forget to carry a printed copy of
            the approved Evisa to the airport.{" "}
          </li>
          <li>
            {" "}
            • The passport must have a 6 months validity from the date of
            arrival in the country.{" "}
          </li>
          <li>
            {" "}
            •The Evisa is valid for 180 days during which travelers can enter
            the country any time. But before the arrival date, no traveler can
            enter &nbsp;&nbsp; the country.{" "}
          </li>
          <li>
            {" "}
            • Before the expiry date of the visa, a traveler must leave the
            country. If a traveler wishes to extend their stay in the country,
            they can  &nbsp;&nbsp;&nbsp; the nearest Cambodia consulate while their visa is
            still valid.{" "}
          </li>
        </ul>

        <h2>DOCUMENTS REQUIRED FOR CAMBODIA VISA</h2>

        <p>
          1. A valid passport: An eligible traveler must have a passport with a
          6 months validity from the date of arrival in the country. The
          passport &nbsp;&nbsp;&nbsp; must contain 2 blank pages.{" "}
        </p>
        <p>
          {" "}
          2. Image of the Bio page of the passport: An image of the bio page of
          the passport is required to crosscheck that the details on the visa
          are  &nbsp;&nbsp;&nbsp;correct to avoid any issues at the time of arrival in the country.
        </p>
        <p>
          {" "}
          3. Invitation letter of business card: Travelers who apply for
          Business Evisa, are supposed to have an invitation letter from
          Cambodia or their  &nbsp;&nbsp;&nbsp;business card with them. It might be asked by the
          Immigration team.
        </p>
        <p>
          {" "}
          4. Even though it is not mandatory to book a hotel prior to their trip
          to Cambodia, details of the hotel booking might be asked in some  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          cases.{" "}
        </p>
        <p>
          {" "}
          5. Have a clear digital passport photograph with a plain background,
          which can also be taken using a phone or camera.{" "}
        </p>
        <p>
          {" "}
          6. Travelers must have a negative PCR test 72 before arrival or double
          vaccination certificate. They might get denied entry if they do not
          &nbsp;&nbsp;&nbsp;&nbsp;have a  negative PCR test or certification.{" "}
        </p>
      </div>
    </div>
  );
};

export default Welcomw;
