import React from "react";
import { useLocation } from "react-router-dom";

const StepFourth = () => {
  const stateParams = useLocation();

  return stateParams?.state?.data?.payment_status === "COMPLETED" ||
    stateParams?.state?.data?.payment_status === "success" ? (
    <table
      width={"100%"}
      border="0"
      align="center"
      cellpadding="0"
      cellspacing="0"
      style={{
        lineHeight: 2,
        fontSize: 15,
        fontFamily: " Arial, Helvetica, sans-serif",
      }}
    >
      <tbody>
        <tr>
          <td
            colspan="3"
            bgcolor="#124e75"
            style={{ padding: 18, color: "rgb(255, 255, 255)" }}
          >
            <span
              class="style1"
              style={{ fontSize: 19, display: "block", paddingBottom: 7 }}
            >
              Dear {stateParams.state.data.payer_name},
            </span>
            <br />
            <span class="style1">
              Congratulation! Your Cambodia e-Visa Payment has been successfully
              received. We Thank You for applying for EVisa to Cambodia. We
              appreciate your interest in Visiting Cambodia. Your Application
              has been successfully submitted and the same&nbsp;is under
              process.
              <br />
              <br />
              Your Application Id is : {stateParams.state.data.temporary_id}.
              <br />
              Amount charged: {stateParams.state.data.amount} USD
              <br />
              <br />
              You will get an update for your visa result in the 3-5 days on
              your registered email after the payment has been confirmed.
              <br />
              Note: <br />
              1. If your payment is successful but you have not yet received an
              email from us, please re-check your SPAM folder before contacting
              us.
              <br />
              2. Please do not enter multiple transactions on your payment page
              otherwise this application will be rejected and there will be no
              refund.
              <br />
              3.If you encounter any problems on your payment page, please do
              not attempt to submit your payment again and please contact us to
              verify your payment status.
              <br />
              4. The visa application fee is 100% non-refundable whether your
              visa is approved, rejected or delayed in the event that your visa
              is approved but you are unable to travel to India.
              <br />
              <br />
              Please feel free to email for any other questions on concerns.
              <br />
              Customer Care is Available 24*7
              <br />
              <br />
              Kindly Regards,
              <br />
              Application Team
              <br />
              Email:{" "}
              <span style={{ color: "#fff" }}>info@evisacambodia-gov.org</span>
              <br />
              Website:
              <span style={{ color: "#fff" }}>
                www.evisacambodia-gov.org
              </span>{" "}
            </span>
            <br />
          </td>
        </tr>
      </tbody>
    </table>
  ) : (
    <div className="contener p-bottom_20 m-bottom_20">
      <div className="hedinginfo"> Payment Status </div>

      <div className="amountColam">
        <h1>
          Your payment is{" "}
          {stateParams?.state?.data?.payment_status === "COMPLETED" ||
          stateParams?.state?.data?.payment_status === "success"
            ? "Successful"
            : "Failed"}{" "}
        </h1>
      </div>
    </div>
  );
};

export default StepFourth;
