import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import {
  ADD_DETAILS,
  LIST_COUNTRIES,
  LIST_VISA_TYPE,
  PREVIEW_APPLICATION,
} from "../constants/api";
import { commonApiCall, uploadFile } from "../services";
import moment from "moment";
import profileImage from "../images/photo-img.jpg";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import passportImage from "../images/pass-img.jpg";

import "react-datepicker/dist/react-datepicker.css";
import { Container } from "react-bootstrap";
const StepFirst = () => {
  const temporaryId = new URLSearchParams(useLocation().search).get("session");
  const navigate = useNavigate();
  const [formData2, setFormData2] = useState({
    entry_purpose: "",
    other_purpose: "",
    letter_of_invitation: "",
  });
  console.log("form data 2 ", formData2);
  const [formData, setFormData] = useState({
    temporary_id: temporaryId ? temporaryId : "0",
    Purpose: "",
    application_photo: "",
    country_birth: "",
    country_nationality: "",
    country_of_passport: "",
    dob: "",
    email: "",
    gender: "",
    given_name: "",
    hotel_reservation: "",
    intended_date_of_entry: "",
    ip_address: "",
    other_document: "",
    passport_date_expiry: "",
    passport_date_issue: "",
    passport_image: "",
    passport_no: "",
    port_of_entry: "",
    residential_address: " ",
    address_visit: "",
    state: "",
    surname: "",
    telephone: "",
    visa_type: "",
    Browser: "chrome",
    website_url: "",
  });
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [visaType, setVisaType] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const handleChange = (event) => {
    // console.log(event.target.name);
    // console.log(event.target.value);
    if (event.target.name === "surname") {
      const result = event.target.value.replace(/[^A-Za-z\s]/gi, "");
      setFormData({ ...formData, [event.target.name]: result });
    } else if (event.target.name === "given_name") {
      const result = event.target.value.replace(/[^A-Za-z\s]/gi, "");
      setFormData({ ...formData, [event.target.name]: result });
    } else if (event.target.name === "telephone") {
      const result = event.target.value.replace(/[^0-9\b]/gi, "");
      setFormData({ ...formData, [event.target.name]: result });
    } else if (event.target.name=== "email") {
      const result = event.target.value.replace(
        /[^[^\s@]+@[^\s@]+\.[^\s@]]/gi,
        ""
      );
      setFormData({ ...formData, [event.target.name]: result });
    } else if (event.target.name === "passport_no") {
      const result = event.target.value.replace(/[^A-Za-z0-9]/gi, "");
      setFormData({ ...formData, [event.target.name]: result });
    } else if (event.target.name === "residential_address") {
      const result = event.target.value.replace(/[^A-Za-z0-9\s]/gi, "");
      setFormData({ ...formData, [event.target.name]: result });
    } else if (event.target.name === "Purpose") {
      const result = event.target.value.replace(/[^A-Za-z\s]/gi, "");
      setFormData({ ...formData, [event.target.name]: result });
    } else if (event.target.name === "state") {
      const result = event.target.value.replace(/[^A-Za-z\s]/gi, "");
      setFormData({ ...formData, [event.target.name]: result });
    } else if (event.target.name === "address_visit") {
      const result = event.target.value.replace(/[^A-Za-z0-9\s]/gi, "");
      setFormData({ ...formData, [event.target.name]: result });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    if (event.target.value !== "") {
      if (errors.hasOwnProperty(event.target.name)) {
        let tmepError = { ...errors };
        delete tmepError[event.target.name];
        setErrors(tmepError);
      }
    }
  };
  const handleChange2 = (event) => {
    if (event.target.name === "other_purpose") {
      const result = event.target.value.replace(/[^A-Za-z0-9\s]/gi, "");
      setFormData2({ ...formData2, [event.target.name]: result });
    } else {
      setFormData2({ ...formData2, [event.target.name]: event.target.value });
    }
  };
  const validateForm = () => {
    let error = {};
    for (const key in formData) {
      if (String(formData[key]).trim() === "") {
        error[key] = "this field is required";
      }
    }
    if(formData.visa_type === "Visa E (Business)"){
      for (const key in formData2) {
        if (String(formData2[key]).trim() === "") {
          error[key] = "this field is required";
        }
      }
      
    }
    console.log(error)
    setErrors(error);
    return Object.keys(error).length === 0;
  };
  const getListData = async () => {
    try {
      let typeResponse = await commonApiCall(LIST_VISA_TYPE, "get");
      let countryResponse = await commonApiCall(LIST_COUNTRIES, "get");
      if (typeResponse.code !== 200 || countryResponse.code !== 200) {
      } else {
        setVisaType(typeResponse.data);
        setCountryList(countryResponse.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleFileChange = async (event) => {
    setLoader(true);
    try {
      if (event.target.files.length > 0) {
        const uploadData = await uploadFile(
          event.target.files[0],
          event.target.name
        );
        if (uploadData.response.code === 200) {
          setFormData({
            ...formData,
            [uploadData.name]: uploadData.response.file,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  };
  const handleFileChange2 = async (event) => {
    setLoader(true);
    try {
      if (event.target.files.length > 0) {
        const uploadData = await uploadFile(
          event.target.files[0],
          event.target.name
        );
        if (uploadData.response.code === 200) {
          setFormData2({
            ...formData2,
            [uploadData.name]: uploadData.response.file,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  };
  useEffect(() => {
    getListData();
    if (temporaryId) {
      getPreviewApplication();
    } else {
      fetchCurrentDetail();
    }
  }, []);
console.log(formData)
  const fetchCurrentDetail = async () => {
    const res = await axios.get("https://ipapi.co/json/");
    setFormData({
      ...formData,
      ip_address:res.data.ip +" "+ res.data.country_name,
      Browser: "chrome",
      website_url: window.location.href,
    });
  };

  const getPreviewApplication = async () => {
    setLoader(true);
    try {
      const response = await commonApiCall(PREVIEW_APPLICATION, "post", {
        visa: window.atob(temporaryId),
      });

      if (response.status === 200) {


        let value = response.data;
        let tempData = { ...formData };
        for (let key in formData) {
          if (value.hasOwnProperty(key)) {
            tempData[key] = value[key];
          }
        }
        setFormData(tempData);

        let value2 = response.data;
        let tempData2 = { ...formData2 };
        for (let key in formData2) {
          if (value2.hasOwnProperty(key)) {
            tempData2[key] = value2[key];
          }
        }
                setFormData2(tempData2);
      }
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  };

  const onSubmitForm = async () => {
    try {
      setLoader(true);
      if (validateForm()) {
        const res = await axios.get("https://ipapi.co/json/");
        let temp = { ...formData };
        temp.ip_address = res.data.ip +" "+ res.data.country_name;
        temp.entry_purpose = formData2.entry_purpose;
        temp.other_purpose = formData2.other_purpose;
        temp.letter_of_invitation = formData2.letter_of_invitation;

        const response = await commonApiCall(ADD_DETAILS, "post", temp);
        if (response.status === 200) {
          navigate("/visa/preview?session=" + window.btoa(response.data));
        }
      }
      console.log("formdata +=============================== ", formData);
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  };

  return (
    <>
      <Loader visibility={loader} />
      <Container>
        <div
          className="p-bottom_20 m-bottom_20 visa_page"
          style={{ backgroundColor: "#eeeeee", float: "left" }}
        >
          <div className="hedinginfo"> Personal Information</div>
          <div className="colam50 pt-4">
            <div className="frameBox mh">
              {/* <div className="passport">onSubmitForm
              <p>
                Attach Passport (.JPG .JPEG .PNG .PDF, size 2MB..)
                <i className="fa fa-question-circle"> </i>
              </p>
              <div className="w-100 floatL">
                <img src={require("../images/pass-img.jpg")} alt="" />
              </div>
              <button className="btn-input m-top_10">Read Passport</button>
            </div> */}
              <div className="passport">
                <p>
                  Passport Photo
                  <i className="fa fa-question-circle"> </i>
                </p>
                <div className="w-100 floatL mb-3">
                  {formData.passport_image === "" ? (
                    <img
                      src={passportImage}
                      alt=""
                      style={{ width: "100%", height: 200, objectFit: "cover" }}
                    />
                  ) : (
                    <img
                      src={
                        process.env.REACT_APP_BASE_URL +
                        "/files/" +
                        formData.passport_image
                      }
                      style={{ width: "100%", height: 200, objectFit: "cover" }}
                      alt=""
                    />
                  )}
                  {/* <img src={imgurl} alt="" /> */}
                  {errors?.passport_image && (
                    <p className="error">{errors?.passport_image}</p>
                  )}
                </div>
                <div className="custom-file-input  flex">
                  <input
                    type="file"
                    className="d-none"
                    onChange={handleFileChange}
                    id="passport"
                    accept="image/jpeg, image/png, image/jpg,application/pdf"
                    name="passport_image"
                    // value={formData.HealthInsurance}
                  />

                  <label htmlFor="passport">
                    {" "}
                    <span className=" btn-primary btn">Upload</span>
                  </label>
                </div>
              </div>

              <div className="profile">
                <p>
                  Application Photo
                  <i className="fa fa-question-circle"> </i>
                </p>
                <div className="w-100 floatL mb-3">
                  {formData.application_photo === "" ? (
                    <img
                      src={profileImage}
                      alt=""
                      style={{ width: "100%", height: 200, objectFit: "cover" }}
                    />
                  ) : (
                    <img
                      src={
                        process.env.REACT_APP_BASE_URL +
                        "/files/" +
                        formData.application_photo
                      }
                      style={{ width: "100%", height: 200, objectFit: "cover" }}
                      alt=""
                    />
                  )}
                  {/* <img src={imgurl} alt="" /> */}
                  {errors?.application_photo && (
                    <p className="error">{errors?.application_photo}</p>
                  )}
                </div>
                <div className="custom-file-input  flex">
                  <input
                    type="file"
                    className="d-none"
                    onChange={handleFileChange}
                    id="profile"
                    accept="image/jpeg, image/png, image/jpg,application/pdf"
                    name="application_photo"
                    // value={formData.HealthInsurance}
                  />

                  <label htmlFor="profile">
                    {" "}
                    <span className=" btn-primary btn">Upload</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="inputdiv star">
              <label className="input-label" htmlFor="surname">
                {" "}
                Surname
              </label>
              <input
                className="input-field"
                type="text"
                id="surname"
                placeholder="Surname"
                onChange={handleChange}
                name="surname"
                // value={formData.surname}
                value={formData.surname}
                // style="text-transform:uppercase"
              />
              {errors?.surname && <p className="error">{errors?.surname}</p>}
            </div>

            <div className="inputdiv star">
              <label className="input-label" htmlFor="givenname">
                {" "}
                Given Names
              </label>
              <input
                className="input-field"
                type="text"
                id="givenname"
                placeholder="Given Names"
                onChange={handleChange}
                value={formData.given_name}
                name="given_name"
              />
              {errors?.given_name && (
                <p className="error">{errors?.given_name}</p>
              )}
            </div>

            <div className="inputdiv star">
              <label className="input-label" htmlFor="telephone">
                {" "}
                Telephone (Telephone number in Cambodia)
              </label>

              <input
                className="input-field"
                type="Telephone"
                pattern="[0-9]*"
                placeholder="Telephone"
                onChange={handleChange}
                name="telephone"
                id="telephone"
                value={formData.telephone}
              />
              {/* {!formData.telephone.match(/^[0-9\b]+$/) && <p className="error">Please enter only Numeric value</p>} */}

              {errors?.telephone && (
                <p className="error">{errors?.telephone}</p>
              )}
            </div>

            <div className="inputdiv star">
              <label className="input-label" htmlFor="email">
                {" "}
                Email
              </label>
              <input
                className="input-field"
                type="email"
                placeholder="example@mail.com"
                id="email"
                onChange={handleChange}
                name="email"
                value={formData.email}
              />
              {/* {!formData.email.match(/^[a-z0-9]+@[a-z]+\.[a-z]{2,3}/) && <p className="error">Please enter valid email</p>} */}
              {errors?.email && <p className="error">{errors?.email}</p>}
            </div>

            {/* <div className='inputdiv star'>
							<label
								className='input-label'
								htmlFor='emailSecondary'>
								{' '}
								Email Secondary
							</label>
							<input
								className='input-field'
								type='email'
								placeholder='example@mail.com'
								onChange={handleChange}
								name='emailSecondary'
								id='emailSecondary'
								value={formData.emailSecondary}
							/>
							{error
								? formData.emailSecondary.length === 0 && (
									<div className='error'>
										Please fill this field
									</div>
								)
								: ''}
						</div> */}
          </div>

          <div className="colam50 pt-4 rightform">
            <div className="inputdiv star">
              <label className="input-label" htmlFor="gender">
                {" "}
                Gender
              </label>
              <select
                className="input-field reset"
                id="gender"
                onChange={handleChange}
                name="gender"
                value={formData.gender}
              >
                <option defaultValue="selected" value="">
                  Gender
                </option>
                <option key={"Male"} value="Male">
                  Male
                </option>
                <option key={"Female"} value="Female">
                  Female
                </option>
              </select>
              {errors?.gender && <p className="error">{errors?.gender}</p>}
            </div>

            <div className="inputdiv star">
              <label className="input-label" htmlFor="dob">
                {" "}
                Date of Birth
              </label>

              <div className="flex">
                <DatePicker
                  selected={formData.dob ? new Date(formData.dob) : null}
                  maxDate={new Date()}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-field"
                  placeholderText="Enter date of birth"
                  onChange={(date) =>
                    handleChange({
                      target: {
                        name: "dob",
                        value: moment(date).format("MM-DD-YYYY"),
                      },
                    })
                  }
                />
              </div>
              {errors?.dob && <p className="error">{errors?.dob}</p>}
            </div>

            <div className="inputdiv star">
              <label className="input-label" htmlFor="CountryRegionofBirth">
                {" "}
                Country / Region of Birth
              </label>
              <select
                className="input-field reset"
                onChange={handleChange}
                name="country_birth"
                id="CountryRegionofBirth"
                value={formData.country_birth}
              >
                <option value="">Country / Region of Birth</option>
                {/* {countries} */}
                {countryList.map((item) => {
                  return (
                    <option key={item.country_code} value={item.country_name}>
                      {item.country_name}
                    </option>
                  );
                })}
                {/* <option value="Afghanistan">Afghanistan</option>
                <option value="Albania">Albania</option> */}
              </select>
              {errors?.country_birth && (
                <p className="error">{errors?.country_birth}</p>
              )}
            </div>
            <div className="inputdiv star">
              <label className="input-label" htmlFor="CountryofNationality">
                Country of Nationality
              </label>
              <select
                className="input-field reset "
                onChange={handleChange}
                name="country_nationality"
                id="CountryofNationality"
                value={formData.country_nationality}
              >
                <option value="">Country of Nationality</option>
                {countryList.map((item) => {
                  return (
                    <option
                      className="text-capitalize"
                      key={item.country_code}
                      value={item.country_name}
                    >
                      {item.country_name.toUpperCase() }
                 
                    </option>
                  );
                })}
              </select>
              {errors?.country_nationality && (
                <p className="error">{errors?.country_nationality}</p>
              )}
            </div>

            <div className="inputdiv">
              <label className="input-label" htmlFor="ResidentialAddress">
                {" "}
                Residential Address
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Residential Address"
                onChange={handleChange}
                name="residential_address"
                id="ResidentialAddress"
                value={formData.residential_address}
              />

              {errors?.residential_address && (
                <p className="error">{errors?.residential_address}</p>
              )}
            </div>

            <div className="inputdiv ">
              <label className="input-label" htmlFor="State">
                State / Province
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="State / Province"
                onChange={handleChange}
                name="state"
                id="State"
                value={formData.state}
              />
              {errors?.state && <p className="error">{errors?.state}</p>}
            </div>

            <div className="inputdiv ">
              <label className="input-label" htmlFor="Address">
                Address During Visit (Address in Cambodia)
              </label>
              <textarea
                className="input-field textarea_fix"
                placeholder="Address During Visit"
                cols="50"
                rows="10"
                onChange={handleChange}
                name="address_visit"
                id="Address"
                value={formData.address_visit}
              ></textarea>
              {errors?.address_visit && (
                <p className="error">{errors?.address_visit}</p>
              )}
            </div>

            {/* <div className="inputdiv">
              <label className="input-label" htmlFor="HeardAboutUs">
                {" "}
                Heard about us from ?
              </label>
              <select
                className="input-field reset"
                onChange={handleChange}
                name="HeardAboutUs"
                id="HeardAboutUs"
                value={formData.HeardAboutUs}
              >
                <option defaultValue="selected" value="">
                  --Heard about us from ?--
                </option>
                <option value="Social Media">Social Media</option>
                <option value="News">News</option>
                <option value="Internet">Internet</option>
              </select>
              {error
                ? formData.HeardAboutUs.length === 0 && (
                    <div className="error">Please fill this field</div>
                  )
                : ""}
            </div> */}
          </div>
        </div>
        <div
          className="contener  m-bottom_20 mt-3 visa_page"
          style={{ backgroundColor: "#eeeeee" }}
        >
          <div className="colam50">
            <div className="hedinginfo mar"> Passport Details</div>

            <div className="inputdiv star">
              <label className="input-label" htmlFor="passport_no">
                Passport Number
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Passport Number"
                onChange={handleChange}
                name="passport_no"
                id="passport_no"
                value={formData.passport_no}
              />

              {errors?.passport_no && (
                <p className="error">{errors?.passport_no}</p>
              )}
            </div>
            <div className="inputdiv star">
              <label className="input-label" htmlFor="passport_date_issue">
                Passport Date of Issue
              </label>
              <div className="flex">
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  selected={
                    formData.passport_date_issue
                      ? new Date(formData.passport_date_issue)
                      : null
                  }
                  maxDate={new Date()}
                  className="input-field"
                  onChange={(date) =>
                    handleChange({
                      target: {
                        name: "passport_date_issue",
                        value: moment(date).format("MM-DD-YYYY"),
                      },
                    })
                  }
                />
              </div>
              {errors?.passport_date_issue && (
                <p className="error">{errors?.passport_date_issue}</p>
              )}
            </div>

            <div className="inputdiv star">
              <label className="input-label">Passport Date of Expire</label>
              <div className="flex">
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  minDate={new Date()}
                  selected={
                    formData.passport_date_expiry
                      ? new Date(formData.passport_date_expiry)
                      : null
                  }
                  className="input-field"
                  onChange={(date) =>
                    handleChange({
                      target: {
                        name: "passport_date_expiry",
                        value: moment(date).format("MM-DD-YYYY"),
                      },
                    })
                  }
                />
              </div>
              {errors?.passport_date_expiry && (
                <p className="error">{errors?.passport_date_expiry}</p>
              )}
            </div>
           
            <div className="inputdiv star">
              <label className="input-label" htmlFor="country_of_passport">
                Country of Passport
              </label>
              <select
                className="input-field reset"
                onChange={handleChange}
                value={formData.country_of_passport}
                name="country_of_passport"
                id="country_of_passport"
              >
                <option key={0} defaultValue="selected" value="">
                  Country of Passport
                </option>
                {countryList.map((item) => {
                  return (
                    <option key={item.country_code} value={item.country_name}>
                      {item.country_name}
                    </option>
                  );
                })}
              </select>
              {errors?.country_of_passport && (
                <p className="error">{errors?.country_of_passport}</p>
              )}
            </div>
            <div className="inputdiv star">
              <label className="input-label" htmlFor="IntendedDateEntry">
                Intended Date of Entry
              </label>
              <div className="flex">
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  selected={
                    formData.intended_date_of_entry
                      ? new Date(formData.intended_date_of_entry)
                      : null
                  }
                  minDate={new Date()}
                  className="input-field"
                  onChange={(date) =>
                    handleChange({
                      target: {
                        name: "intended_date_of_entry",
                        value: moment(date).format("MM-DD-YYYY"),
                      },
                    })
                  }
                />
              </div>
              {errors?.intended_date_of_entry && (
                <p className="error">{errors?.intended_date_of_entry}</p>
              )}
            </div>

            <div className="inputdiv star">
              <label className="input-label" htmlFor="portofEntry">
                Port of Entry
              </label>
              <select
                className="input-field reset"
                onChange={handleChange}
                name="port_of_entry"
                id="portofEntry"
                value={formData.port_of_entry}
              >
                <option defaultValue="selected" value="">
                  Port of Entry
                </option>
                <option value=" Phnom Penh International Airport">
                  Phnom Penh International Airport
                </option>
                <option value="Siem Reap International Airport">
                  Siem Reap International Airport
                </option>
                <option value="Cham Yeam (Koh Kong)">
                  Cham Yeam (Koh Kong)
                </option>
                <option value="Poi Pet (Banteay Meanchey)">
                  {" "}
                  Poi Pet (Banteay Meanchey)
                </option>
                <option value="Bavet (Svay Rieng)">Bavet (Svay Rieng)</option>
                <option value="Tropaeng Kreal Border Post (Stung Treng)">
                  Tropaeng Kreal Border Post (Stung Treng){" "}
                </option>
                <option value="Sihanoukville International Airport (Not Cruising Ships)">
                  {" "}
                  Sihanoukville International Airport (Not Cruising Ships){" "}
                </option>
              </select>
              {errors?.port_of_entry && (
                <p className="error">{errors?.port_of_entry}</p>
              )}
            </div>
          </div>

          <div className="colam50 rightside">
            <div className="hedinginfo mar"> Other Details</div>

            <div className="inputdiv star">
              <label className="input-label" htmlFor="VisaType">
                Visa Type
              </label>
              <select
                className="input-field reset"
                onChange={handleChange}
                name="visa_type"
                id="VisaType"
                value={formData.visa_type}
              >
                <option value="">Visa Type</option>
                {visaType.map((item, i) => {
                  return (
                    <option key={i} value={item.visa_type}>
                      {item.visa_type}
                    </option>
                  );
                })}
                {/* <option value="Visa 36">Visa 36</option>
              <option value="Visa 35">Visa 35</option> */}
              </select>
              {errors?.visa_type && (
                <p className="error">{errors?.visa_type}</p>
              )}
            </div>
            {formData.visa_type === "Visa E (Business)" && (
              <div className="inputdiv star">
                <label className="input-label" htmlFor="entry_purpose">
                  Entry purpose
                </label>
                <select
                  className="input-field reset"
                  onChange={handleChange2}
                  name="entry_purpose"
                  id="entry_purpose"
                  value={formData2.entry_purpose}
                >
                  <option defaultValue="selected" value="">
                    select
                  </option>
                  <option value="Business">Business</option>
                  <option value="General">General</option>
                  <option value="Project">Project</option>
                  <option value="Retired"> Retired</option>
                  <option value="Student">Student</option>
                  <option value="Technical">Technical</option>
                  <option value="Meeting"> Meeting </option>
                  <option value="Other">Other </option>
                </select>
                {errors?.port_of_entry && (
              <p className="error">{errors?.port_of_entry}</p>
            )}
              </div>
            )}

            {formData.visa_type === "Visa E (Business)" && (
              <div className="inputdiv">
                <label className="input-label" htmlFor=" Other Purpose">
                  {" "}
                  Other Purpose
                </label>
                <input
                  className="input-field"
                  type="text"
                  id="other_purpose"
                  placeholder="other purpose"
                  onChange={handleChange2}
                  name="other_purpose"
                  value={formData2.other_purpose}
                />
                 {errors?.other_purpose && (
                  <p className="error">{errors?.other_purpose}</p>
                )}

                {/* <textarea
              className="input-field textarea"
              placeholder="other purpose"
             
              onChange={handleChange}
              name="Other Purpose"
              id="other_purpose"
              value={formData.other_purpose}
            ></textarea> */}

                {/* {errors?.other_purpose && <p className="error">{errors?.other_purpose}</p>} */}
              </div>
            )}

            <p>Support Document(s) (.JPG .JPEG .PNG .PDF, size 2MB..)</p>
            {/* {formData.visa_type === "Visa E (Business)" && (
              <div className="inputdiv">
                <label className="input-label">1. Health Insurance</label>
                <div className="flex">
                  <div className="custom-file-input flex">
                    <input
                      type="file"
                      className="d-none"
                      onChange={handleFileChange}
                      id="HealthInsurance"
                      accept=".pdf, image/jpeg, image/png, image/jpg"
                      name="health_insurance"
                      // value={formData.HealthInsurance}
                    />

                    <input
                      type="text"
                      className="input-field"
                      placeholder="health_insurance_document.pdf"
                      readOnly
                      value={formData.health_insurance}
                    />
                    <label htmlFor="HealthInsurance">
                      {" "}
                      <span className=" btn-primary btn">Attach</span>
                    </label>
                  </div>
                </div>
                {errors?.health_insurance && (
                  <p className="error">{errors?.health_insurance}</p>
                )}
              </div>
            )} */}

            {formData.visa_type === "Visa E (Business)" && (
              <div className="inputdiv">
                <label className="input-label">Letter Of Invitation</label>
                <div className="flex">
                  <div className="custom-file-input flex">
                    <input
                      type="file"
                      className="d-none"
                      accept=".pdf, image/jpeg, image/png, image/jpg"
                      onChange={handleFileChange2}
                      name="letter_of_invitation"
                      id="letter_of_invitation"
                      // value={formData.Vaccine}
                    />
                    <input
                      type="text"
                      className="input-field"
                      value={formData2.letter_of_invitation}
                      readOnly
                      placeholder="invitation_letter.pdf"
                    />
                    <label htmlFor="letter_of_invitation">
                      <span className=" btn-primary btn">Attach</span>
                    </label>
                  </div>
                </div>
                {errors?.letter_of_invitation && (
                  <p className="error">{errors?.letter_of_invitation}</p>
                )}
              </div>
            )}

            <div className="inputdiv">
              <label className="input-label">
                Hotel Reservation Document(s)
              </label>
              <div className="flex">
                <div className="custom-file-input flex">
                  <input
                    type="file"
                    className="d-none"
                    accept=".pdf, image/jpeg, image/png, image/jpg"
                    onChange={handleFileChange}
                    name="hotel_reservation"
                    id="HotelReservations"
                    // value={formData.HotelReservations}
                  />
                  <input
                    type="text"
                    className="input-field"
                    readOnly
                    value={formData.hotel_reservation}
                    placeholder="hotel_reservation.pdf"
                  />
                  <label htmlFor="HotelReservations">
                    <span className=" btn-primary btn">Attach</span>
                  </label>
                </div>
              </div>
              {errors?.hotel_reservation && (
                <p className="error">{errors?.hotel_reservation}</p>
              )}
            </div>

            <div className="inputdiv">
              <label className="input-label"> Other</label>
              <div className="flex">
                <div className="custom-file-input flex">
                  <input
                    type="file"
                    className="btn-input"
                    accept=".pdf, image/jpeg, image/png, image/jpg"
                    onChange={handleFileChange}
                    name="other_document"
                    id="Others"
                    // value={formData.Others}
                  />
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Others files.pdf"
                    value={formData.other_document}
                  />
                  <label htmlFor="Others">
                    {" "}
                    <span className=" btn-primary btn">Attach</span>
                  </label>
                </div>
              </div>
              {errors?.other_document && (
                <p className="error">{errors?.other_document}</p>
              )}
            </div>
            <div className="inputdiv star">
              <label className="input-label" htmlFor="Purpose">
                Purpose
              </label>
              <textarea
                className="input-field textarea_fix"
                placeholder="Purpose"
                cols="50"
                rows="10"
                onChange={handleChange}
                name="Purpose"
                id="Purpose"
                value={formData.Purpose}
              ></textarea>
              {errors?.Purpose && <p className="error">{errors?.Purpose}</p>}
            </div>
          </div>
        </div>
        <div className="needbox">
          {/* <Link to="#" className="need">
            Need help?
          </Link> */}
          <div className="w-100 floatL m-top_20">
            <a href="/" disabled={loader} className="btn-input ">
              Cancel and Back to Home
            </a>
            <button
              onClick={onSubmitForm}
              disabled={loader}
              type="button"
              className="btn-input"
            >
              {loader ? "Processing..." : "Next"}
            </button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default StepFirst;
