import React from "react";

const Privacy = () => {
  return (
    <div className="centerCol">
      <div className="welcomeText">
        <h2>Privacy Policy</h2>
        <p>
          The privacy policy describes how  <a href="www.cambodiaevisa.org">www.cambodiaevisa.org</a> is using and protecting any detail or information that you provide while you are on this website. e-Visa Cambodia is committed to ensure your privacy. We collect certain information by which you can be identified while using this website, and then you can be assured that it will only be used in accordance with this privacy statement. We may change this policy from time to time by updating the content of this page. For regular updates you may check this page time and again.
        </p>
        <h2>
          We may collect the following information:
        </h2>
        <p>
          Name and other personal details filled in the e-Visa application form on this website. Contact information such as email address and phone numbers so that you can be contacted as and when required for more information in relation to your e-Visa application. We certainly need the demographic information such as address, postcode for processing the application and identify your location. We may require other information relevant to the applicant for completion our services.
        </p>
        <h2>Purpose of collecting the information:</h2>
        <p>
          We require this information to understand your needs and provide you with a better service, for internal record keeping. We may use the information to contact you for feedback and improve our services. We do not send any emails about offers or promotions however we do follow up through emails or phone, on the incomplete application submitted by you in order to find out if you need our services. We may be required to use your information to contact you for market research purposes and your feedback. We may contact you by email, phone, fax or mail.
        </p>
        <h2>
          Security Measures:
        </h2>
        <p>
          We are committed to safeguard all your valuable and confidential information. To prevent your information from any unauthorised access or infringement we have put in place suitable checks in the form of physical, electronic and managerial procedures to secure the information we collect online.
        </p>
        <h2>How we control your personal Information?</h2>
        <p>
          We collect your personal information only for processing the Cambodia e-visa application direct and not for marketing purposes, you may also change your mind at any time by writing us at info@cambodiaevisa.org. if you don't want to be contacted by us. We never sell, distribute or share your personal and confidential information to any third parties except the Government of India as and when required by the Indian law to do so. You may request a copy of the information held on you under the Data Protection Act 1998.
        </p>
        <p>
          Please feel free to email us, if you believe that any of your information held by us is incorrect or incomplete. We will make the necessary corrections as soon as possible.
        </p>
        <h2>Cookies</h2>
        <p>
          www.cambodiaevisa.org uses cookies to recognize you and other clients when visiting our site. That allows us to customize your experience on our website. Moreover, for the marketing purpose, we use the remarketing or similar audiences feature in our website by Google's Cookies and the DoubleClick's remarketing pixels. The third-party vendors including Google can use cookies to serve ads based on your past visits to our website
        </p>
        <p>
          You may need to have cookies "turned on" or enabled in the browser you use in order to register. However, if you don't want to use Cookies and store your information in our website, you can choose to disable cookies by visiting Google's Ads Settings or visiting the Network Advertising Initiative opt-out page. You are still able to navigate the site after the cookies have been disabled but some information in our website will not be visible. And to opt out of DoubleClick's use of cookies by visiting the DoubleClick opt-out page. To find out more about how Google uses any data it collects,
        </p>
        <p>
          please visit please visit <a href="http://www.google.com/policies/privacy/ads/.">http://www.google.com/policies/privacy/ads/.</a>  Cookies do not store any personal information. All the information is entirely confidential and is never sold or seen outside of the company. cambodiaevisa.org may display links or advertisement to other sites and companies that may also use cookies. In such cases, we cannot be held responsibility for any or all information that these parties collect through the use of cookies. You are hereby advised to familiarize yourself with privacy policies and information sharing standards of these sites, as they may be different from the policies and standards of cambodiaevisa.org.
        </p>
        <h2>Updating, Changing or Modify Information
        </h2>
        <p>
          Please let us know if the personal information which we hold about you needs to be corrected or updated. Once the approval letter is issued by Cambodia Immigration Department, we are unable to update or change any information; hence a new application for e-Visa will be required.
        </p>
        <h2>System information</h2>
        <p>
          cambodiaevisa.org reserves the right to collect and store such information as the IP address, browser type or operating system type. All the information is highly confidential and will be used exclusively for system administration purposes. This information helps diagnose problems, monitor traffic and site usage.
        </p>
        <h2>
          Policy Amendments
        </h2>
        <p>
          We reserve the rights to update or amend this privacy policy anytime by posting a new version on our website. You should check this page occasionally to ensure you are aware of these changes.
        </p>
        <h2>Third party websites</h2>
        <p>
          The website contains links to other websites. We are not responsible for the privacy policies or practices of third party websites.
        </p>
        <h2>Payment through third party (Financial Transactions)</h2>
        <p>
          In the website we have integrated online payment tool, but here we accept the shaft of the Bank as Paypal pocket electronic payment. For ease of payment, we accept credit cards, PayPal and direct bank transfer online. All credit card information, PayPal account is required to pay visa fees and will be transferred to our partners such as Paypal.com, and all your information protected. We recommend that you read the terms and conditions of the policy very carefully.
        </p>
        <h2>Noted:</h2>
        <p>We are not responsible for the privacy policies or practices of third party payment gate.
        </p>
        <h2>Contact us</h2>
        <p>If you have any questions about this privacy policy, please contact us: </p>
        <p>info@cambodiaevisa.org</p>
        <p>By accessing this site and using its services, you unconditionally agree with our Policy and Terms of Service. If you don't agree to all or any of the terms of this Policy, please do not use this site.</p>
      </div>
    </div>
  );
};

export default Privacy;
