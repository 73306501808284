import React from "react";

const Documents = () => {
  return (
    <div className="centerCol">
      <div className="welcomeText">
        <h2>Documents Required for a Cambodia e-Visa</h2>
        <p>Let’s look at the documents required to get you an approved e-Visa on Arrival.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Passport : </span>
          You need to provide scanned front and back bio pages of the passport. Ensure before applying that your passport has a validity of a minimum of six months on the intended date of entering the country.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Photograph :</span> Another mandate to secure an e-VOA is a scanned passport-size photograph in a white background.
        </p>
        <p> <span style={{ fontWeight: "bold" }}>Flight Tickets :</span>You also need to furnish the confirmed return flight tickets. In case you are not returning to your country, then tickets to your onward destination.</p>
        <p>
          <span style={{ fontWeight: "bold" }}>Hotel Booking :</span> Make sure you have done your hotel booking beforehand to secure an approved electronic visa on arrival. You need to produce the hotel booking voucher that accounts for your stay in Cambodia at the time of your visit.
        </p>
        <h2>Port of entry to Cambodia
        </h2>
        <p>
          Here’s a list of the airports that accept the e-Visa on arrival. So, in case you are applying for an electronic visa you should be aware whether or not you can enter the country from any of these ports. These comprise:
        </p>
        <ul>
          <li><span style={{ fontWeight: "bold" }}>1.</span>Phnom Penh International Airport
          </li>
          <li><span style={{ fontWeight: "bold" }}>2.</span>Siem Reap Angkor International Airport
          </li>
          <li><span style={{ fontWeight: "bold" }}>3.</span>Cham Yeam Airport (Koh Kong)
          </li>
          <li><span style={{ fontWeight: "bold" }}>4.</span> Poi Pet (Banteay Meanchey)
          </li>
          <li><span style={{ fontWeight: "bold" }}>5.</span> Bavet (Svay Rieng)
          </li>
          <li><span style={{ fontWeight: "bold" }}>6.</span> Sihanoukville International Airport
          </li>
          <li><span style={{ fontWeight: "bold" }}>7.</span>Tropaeng Kreal Border Post (Stung Treng)
          </li>
        </ul>
        <h2>Cambodia Tourist e-Visa Validity
        </h2>
        <p>
          To travel to Cambodia for the purposes of tourism, you need to get yourself a Type T visa as it is the one offered to tourists planning a holiday in Cambodia. The Tourist e-Visa is valid for 90 days from the date of approval. It is a single-entry visa which lets you stay in the country for a maximum of 30 days.
        </p>
      </div>
    </div>
  );
};

export default Documents;
