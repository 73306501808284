import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { payment } from "../components/payment";
import { PREVIEW_APPLICATION } from "../constants/api";
import { commonApiCall } from "../services";
import razorpayImage from "../images/razorpay.svg";

const StepThird = ({ amount }) => {
  const navigate = useNavigate();
  const temporaryId = new URLSearchParams(window.location.search).get("visa");
  const [paymentParams, setPaymentParams] = useState(null);
  const [showPaypal, setShowPayPal] = useState(false);
  console.log(paymentParams);

  useEffect(() => {
    getPreviewApplication();
  }, [temporaryId]);

  const getPreviewApplication = async () => {
    try {
      const response = await commonApiCall(PREVIEW_APPLICATION, "post", {
        visa: temporaryId,
      });
      console.log(response.data);
      if (response.status === 200) {
        getVisaFees(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getVisaFees = async (data) => {
    try {
      const response = await commonApiCall("/visa/fee", "post", {
        country_code: data.country_nationality,
        visa_type: data.visa_type,
      });
      if (response.status === 200) {
        data.amount = response.data;
        // console.log(data);
        setPaymentParams(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const makePayment = () => {
    try {
      payment.displayRazorpay(paymentParams, (data) => {
        navigate("/visa/thank-you", {
          state: data,
        });
      });
    } catch (err) {
      console.log(err);
    }
  };



  return (
    <div className="contener p-bottom_20 m-bottom_20">
      <div className="hedinginfo"> Application Form </div>

      <div className="amountColam">
        <h1>
          Your total amount is <span>{paymentParams?.amount}.00 USD</span>
        </h1>
      </div>

      <div className="paymentBox">
        <p>Choose Payment Method</p>

        <div className="payment">
          <ul className="mb-4">
            <li>
              <button onClick={makePayment}>
                <img src={razorpayImage} alt="razorpay" />
              </button>
            </li>
            <li>
              <button onClick={() => setShowPayPal(true)}>
                <img src={require("../images/PayPal.svg.png")} alt="paypal" />
              </button>
            </li>
          </ul>
          {showPaypal && (
            <div className="w-100 mt-3">
              <PayPalScriptProvider
                options={{
                  //process.env.PAYPAL_CLIENT_ID,
                  // "client-id":
                  //   "AfE5j9bsIoO24fOsVITa3S3RBKrgHFZRowWYQMFTaI1YoqrpZMNehOE5hcQ1KD9ju6kdLPZf8eR1wDea",
                  "client-id":
                    "Afiq0_xQoExVYhB3VZDXhqByVVPuifLkhxcbWOhndqTEYNBQu9ahCRh-lZ7w8e6dQMhj9q0KwKrzOSYE",
                }}
              >
                <PayPalButtons
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value: parseInt(paymentParams?.amount),
                          },
                          description:
                            "Ticket ID: " + paymentParams?.temporary_id,
                        },
                      ],
                    });
                  }}
                  onApprove={(data, actions) => {
                    return actions.order.capture().then(async (details) => {
                      let param = {
                        order_id: details.id,
                        status: null,
                        passport_no: paymentParams?.passport_no,
                        payment_id: details.purchase_units[0].id,
                        order_status:
                          details.status === "VOIDED" ||
                          details.status === "PAYER_ACTION_REQUIRED"
                            ? "Failed"
                            : "Confirmed",
                        tran_id: null,
                        temporary_id: paymentParams?.temporary_id,
                        pay_id: details.payer.payer_id,
                        state: null,
                        cart: null,
                        payment_method: "paypal",
                        payment_status: details.status,
                        amount: details.purchase_units[0].amount.value,
                        currency:
                          details.purchase_units[0].amount.currency_code,
                        description: null,
                        invoice_number: null,
                        payer_email: paymentParams?.email,
                        payer_name: paymentParams?.surname,
                        create_time: null,
                        ep_tran_id: null,
                      };
                      let response = await commonApiCall(
                        "/payment/updatePayment",
                        "post",
                        param
                      );
                      if (response.status === 200) {
                        navigate("/visa/thank-you", {
                          state: param,
                        });
                      }
                    });
                  }}
                  // onCancel={async (data, actions) => {
                  //   let failed = {
                  //     order_id: paymentJson.receipt,
                  //     status: null,
                  //     payment_id: null,
                  //     order_status: "Failed",
                  //     tran_id: null,
                  //     temporary_id: paymentJson.temporary_id,
                  //     pay_id: null,
                  //     state: null,
                  //     cart: null,
                  //     payment_method: "razorpay",
                  //     payment_status: "failed",
                  //     amount: paymentJson.amount,
                  //     currency: paymentJson.currency,
                  //     description: null,
                  //     invoice_number: null,
                  //     payer_email: null,
                  //     payer_name: paymentJson.name,
                  //     create_time: null,
                  //     ep_tran_id: null,
                  //   };
                  //   let response = await commonApiCall(
                  //     "api/payment",
                  //     "post",
                  //     failed
                  //   );
                  //   if (response.status === 200) {
                  //     setPaymentDetail(failed);
                  //     setShowConfirm(false);

                  //     setLoading(false);
                  //     navigate("/ticket/response", {
                  //       state: failed,
                  //     });
                  //   }
                  // }}
                />
              </PayPalScriptProvider>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepThird;
