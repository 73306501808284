import React from "react";

const RefuntPolicy = () => {
    return (
        <div className="centerCol">
            <div className="welcomeText">
                <h2>Refund and cancellation of the e-Visa service fees</h2>
                <p>cambodiaevisa.org  gives it's users a chance to apply for refund /cancellation in case the applicants want to discontinue with our services. The applicants can apply for refund within 30 Minutes of submission of application subject to the following condition:-
                </p>
                <p>
                    <span style={{ fontWeight: "bold" }}>A)</span>No refund/cancellation in case the travel is within 24-72 hours.
                </p>
                <p>
                    <span style={{ fontWeight: "bold" }}>B)</span> If we received your request for cancellation/refund within 30 Minutes after the submission of application, we will refund the payment after deduction of 50% towards the cancellation charges .
                </p>
                <p>
                    <span style={{ fontWeight: "bold" }}>C)</span>If you wish to request a refund, email us at info@cambodiaevisa.org indicating the following Your reason for the request.
                </p>
                <p>Your reason for the request.</p>
                <p>Your full names (as appears in your passport).</p>
                <p>Your unique transaction ID.</p>
                <p>The email used to make the registration on this website.
                </p>
                <p>
                <span style={{fontWeight:"bold"}}>D)</span>All refund requests will be processed within 72 hours.
                </p>
            </div>
        </div>
    );
};

export default RefuntPolicy;
