import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React from "react";
// import ReactDOM from "react-dom"
import { commonApiCall } from "../services";
// const PayPalButtons=window.paypal.Buttons.driver('react',{React,ReactDOM})
const PayPalGateway = ({ payment, callback }) => {
  console.log(payment)
  // function createOrder() {
  //   return fetch("http://localhost:4000/paypal/create-paypal-order", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     // use the "body" param to optionally pass additional order information
  //     // like product ids and quantities
  //     body: JSON.stringify({
  //       cart: [
  //         {
  //           id: 12,
  //           quantity:2,
  //         },
  //       ],
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((order) => order.id);
  // }
  //  function onApprove(data) {
  //   return fetch("http://localhost:4000/paypal/capture-paypal-order", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       orderID: data.orderID
  //     })
  //   })
  //     .then((response) => response.json())
  //     .then((orderData) => {
  //       const name = orderData.payer.name.given_name;
  //       alert(`Transaction completed by ${name}`);
  //     });

  // }
// useEffect(()=>{
// window.paypal.Buttons(z)
// },[])

  return (
    <PayPalScriptProvider
      options={{
        "client-id":"AWh0-PQIA71RqgV4qrNApErYXtzQsdEioEhuzFrzqM7Xwur8j9VlpM9wwaDzAHKqog3h9umdWK2qX3A_",
        // "client-id":"Ad3s--Iqvofg6fKolXj9GL-ePVteftO3GvSf_dq7TvNqFcFcG08FXl43oOibnXrky63VcTxk66seDOGm",
      }}
    >
      <PayPalButtons
      // createOrder={createOrder}
      // onApprove={onApprove}
        createOrder={async (data, actions) => {
          return await actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: Number(payment.amount)
                },
                description: "Cambodia Application ID" + payment.temporary_id,
              },
            ],
          });
        }}
      onApprove={async (data, actions) => {
        // return payment;
        return await actions.order.capture().then(async (details) => {
          let param = {
            order_id: details.id,
            status: null,
            payment_id: details.purchase_units[0].id,
            order_status:
              details.status === "VOIDED" ||
                details.status === "PAYER_ACTION_REQUIRED"
                ? "Failed"
                : "Confirmed",
            tran_id: null,
            temporary_id: payment.temporary_id,
            pay_id: details.payer.payer_id,
            state: null,
            cart: null,
            payment_method: "paypal",
            payment_status: details.status,
            amount: details.purchase_units[0].amount.value,
            currency: details.purchase_units[0].amount.currency_code,
            description: null,
            invoice_number: null,
            payer_email: payment.email,
            payer_name: payment.surname,
            create_time: null,
            ep_tran_id: null,
            passport_no: payment.passport_no,
            ip_address:payment.ip_address,
            country_nationality:payment.country_nationality,
            intended_date_of_entry:payment.intended_date_of_entry,
            visa_type:payment.visa_type
          };
          let response = await commonApiCall(
            "/payment/updatePayment",
            "post",
            param
          );
          if (response.valid) {
            callback(param);
          }
        });
      }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalGateway;
