import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Container } from "react-bootstrap";

const VisaLayout = () => {
  const temporaryId = new URLSearchParams(window.location.search).get("visa");
  const location = useLocation();
  console.log("location ",location);
  console.log("temporaryId ",temporaryId);
  return (
    <div>
      <Header />
      <Container>
        <div className="navform mt-4  m-bottom_20">
          {/* <ul>
            <li
              className={
                (location.pathname === "/visa/apply" ||
                  location.pathname === "/visa") &&
                temporaryId === null
                  ? "active"
                  : ""
              }
            >
              <div className="btn-circle">1</div>
              <p>APPLICATION</p>
            </li>
            <li
              className={
                location.pathname === "/visa/preview" && temporaryId
                  ? "active"
                  : ""
              }
            >
              <div className="btn-circle">2</div>
              <p>REVIEW</p>
            </li>
            <li
              className={location.pathname === "/visa/payment" ? "active" : ""}
            >
              <div className="btn-circle">3</div>
              <p>PAYMENT</p>
            </li>
            <li
              className={
                location.pathname === "/visa/thank-you" ? "active" : ""
              }
            >
              <div className="btn-circle">4</div>
              <p>COMPLETE</p>
            </li>
          </ul> */}
        </div>
        <Outlet />
      </Container>
      <Footer />
    </div>
  );
};

export default VisaLayout;
