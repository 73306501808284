import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import 'react-loader-spinner/dist/'
import "./App.css";
import About from "./pages/About";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import Documents from "./pages/Documents";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import HomeLayout from "./layout/home-layout";
import Welcomw from "./components/home/Welcome";
import VisaLayout from "./layout/visa-layout";
import StepFirst from "./steps/step-first";
import StepThird from "./steps/step-third";
import StepFourth from "./steps/step-fourth";
import StepSecond from "./steps/step-second";
import PartialForm from "./steps/partial-form";
import RefuntPolicy from "./pages/Refund-policy";
function App() {
  return (
    <>
      <Router basename="/">
        <Routes>
          <Route path="/" exact element={<HomeLayout />}>
            <Route path="welcome" element={<Welcomw />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="terms" element={<Terms />} />
            <Route path="refund" element={<RefuntPolicy />} />
            <Route path="documents" element={<Documents />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="faq" element={<Faq />} />
            <Route index element={<Welcomw />} />
            <Route path="PartialForm" element={<PartialForm />} />
          </Route>
          <Route path="visa" element={<VisaLayout />}>
            <Route path="apply" element={<StepFirst />} />
            <Route path="preview" element={<StepSecond />} />
            <Route path="payment" element={<StepThird />} />
            <Route path="thank-you" element={<StepFourth />} />
            <Route index element={<StepFirst />} />
          </Route>
          {/* <Route path="/visa" element={<VisaLayout />}>
            <Route path="/step" element={<Step1 />} />
            <Route index element={<Step1 />} />
          </Route> */}
          {/* <Route path="/home" exact element={<Home />} /> */}

          {/* <Route path="/apply" exact element={<Apply />} />
          <Route path="/apply" exact element={<Step1 />} /> 
          <Route path="/review" exact element={<Step2 />} />
          <Route path="/payment" exact element={<Step3 />} />
          <Route path="/complete" exact element={<Step4 />} />
          <Route path="/test" exact element={<Test />} />
          <Route path="/payment_failed" element={<Payment_failed />} /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
