import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { commonApiCall } from "../services";
import { PREVIEW_APPLICATION } from "../constants/api";

const PartialForm = () => {
  const navigate = useNavigate();

  const [applicationId, setApplicationId] = useState({
    applicationId: "",
    user_captcha: "",
    errors: {},
  });

  const { errors } = applicationId;
  const handleChange = (event) => {
    setApplicationId({
      ...applicationId,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    loadCaptchaEnginge(5);
  }, []);

  const getApplicationData = async () => {
    try {
      if (validateForm()) {
        const response = await commonApiCall(PREVIEW_APPLICATION, "post", {
          visa: applicationId.applicationId,
        });

        if (response.status === 200) {
          console.log(response.data.fees_status,"234567")
          if (!response.data.fees_status) {
            navigate(
              "/visa/preview?session=" +
                window.btoa(applicationId.applicationId)
            );
          } else {
            alert(
              "Your Application has been completed successfully you can not modify your application Kindly contact us on info@cambodiaevisa.org"
            );
          }
        } else {
          setApplicationId({
            ...applicationId,
            errors: { applicationId: "required" },
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = () => {
    let error = {};

    if (applicationId.applicationId === "") {
      error.applicationId = "required";
    } else {
      delete error.applicationId;
    }

    if (
      applicationId.user_captcha === "" ||
      !validateCaptcha(applicationId.user_captcha)
    ) {
      error.user_captcha = "required";
    } else {
      delete error.user_captcha;
    }

    setApplicationId({ ...applicationId, errors: error });

    return Object.keys(error).length === 0;
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 m-auto">
          <div className="contener partial_form">
            <div className="hedinginfo"> Review Application </div>

            {/* <div className="amountColam">
        <h1>
          Your payment is{" "}
          {stateParams?.state?.data?.payment_status === "COMPLETED" ||
          stateParams?.state?.data?.payment_status === "success"
            ? "successful"
            : "Failed"}{" "}
        </h1>
      </div> */}

            <div className="paymentBox paymentContainer">
              <div class="page-completed-ui p-5">
                <div className="d-flex justify-content-center">
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label className="mb-2">Application ID</label>
                      <Form.Control
                        name="applicationId"
                        onChange={handleChange}
                        type={"text"}
                        isInvalid={errors?.applicationId}
                      />
                    </div>
                    <LoadCanvasTemplate />
                    <div className="form-group mt-3">
                      <Form.Control
                        name="user_captcha"
                        placeholder="fill captcha"
                        onChange={handleChange}
                        type={"text"}
                        isInvalid={errors?.user_captcha}
                      />
                    </div>
                    <button
                      type="button"
                      onClick={getApplicationData}
                      className="btn-primary rounded-3 mt-3"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartialForm;
