import axios from "axios";

export const commonApiCall = async (url, type, body) => {
  try {
    const response = await axios({
      method: type,
      url: process.env.REACT_APP_BASE_URL + url,
      data: body,
    });
    if (response.status !== 200) {
      console.log(response.statusText);
    } else {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const uploadFile = async (file, name) => {
  try {
    const formData = new FormData();
    formData.append("image", file);
    const response = await axios({
      method: "post",
      url: process.env.REACT_APP_BASE_URL + "/upload",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status !== 200) {
      console.log(response.statusText);
    } else {
      return { name, response: response.data };
    }
  } catch (err) {
    console.log(err);
  }
};
