import React from "react";
import { Link, Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import banner from "../images/banner-img.jpg";

const HomeLayout = () => {
  return (
    <div>
      <Header />
      <section className="bannerBox">
        <img src={banner} alt="" />
        <div className="banner">
          <h2>
            <span>Welcome to</span> E-Visa{" "}
            <span className="text">Services </span>
          </h2>
          <div className="bottom">
            <p>
              <span>Get your </span>e-visa in 3 easy steps<span>!</span>
            </p>
            <Link to="/visa">
              <span>Apply Now</span>{" "}
            </Link>
          </div>
        </div>
      </section>
      <section className="bannerCntr">
        <div className="centerCol">
          <ul>
            <li>
              <p>
                <Link to="/visa" style={{ color: "#124e75" }}>
                  Apply e-Visa
                  <br /> Cambodia
                </Link>
              </p>
            </li>
            <li>
              <p>
                {" "}
                <Link to="/PartialForm" style={{ color: "#124e75" }}>
                  Complete Partialy Filled Form
                  <br />
                </Link>
              </p>
            </li>
            <li>
              <p>
                {" "}
                <Link to="/PartialForm" style={{ color: "#124e75" }}>
                  Make Payment for Completed Form
                  <br />
                </Link>
              </p>
            </li>
          </ul>
        </div>
      </section>
      <Outlet />
      <Footer />
    </div>
  );
};
export default HomeLayout;
