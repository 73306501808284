import React from "react";

const Terms = () => {
  return (
    <div className="centerCol">
      <div className="welcomeText">
        <h2>Terms and Conditions</h2>
        <p>
          Welcome to our website www.cambodiaevisa.org . The Website Owner, including subsidiaries and affiliates ( "Website" or "Website Owner" or "we" or "us" or "our") provides the information contained on the website or any of the pages comprising the website ( "website" ) to visitors ("visitors") (cumulatively referred to as "you" or "your" hereinafter) subject to the Terms and Conditions set out on this website, the Privacy policy and any other relevant Terms & Conditions, policies and notes which may be applicable to a specific section or module of our website.
        </p>
        <p>
          While you continue to use this website in any manner, you agree to comply with and shall be bound by the following terms and conditions of use, which together with the privacy policy govern our relationship with you in relation to this website. "We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly from the decline of authorization against any Transaction.
        </p>
        <p>Passport should have a validity of six months from the date of arrival in Cambodia. International Travellers should have return ticket or onward journey ticket, with sufficient money to spend during his/her stay in Cambodia. Applicants of the eligible countries are requested to apply online minimum 4 days in advance of the date of arrival with a window of 180 days. e-Visa is valid for 30 days from the date of arrival. The visa must be availed within its period of validity. The e-Visa for the purpose of Tourism and Business Visa will be valid for Single entry from the date of arrival date in Cambodia.</p>
        <p>
          <span style={{ fontWeight: "bold" }}>07 Port of Entry:-</span> (i.e. Phnom Penh International Airport, Siem Reap Angkor International Airport, Cham Yeam (Koh Kong), Poi Pet (Banteay Meanchey), Bavet (Svay Rieng), Sihanoukville International Airport (Not Cruising Ships), Tropaeng Kreal Border Post (Stung Treng)
        </p>
        <p>
          Applicants are requested to carry a copy of e-Visa while travelling. On arrival in Cambodia the biometric of the applicant will be mandatory captured at Immigration. The applicant is requested to fill the Visa Application Form accurately and submit the same with the applicable visa fees and mentioned requirements. Incomplete and double applications will not be accepted. The applicant is requested to provide correct email ID, since we send the e-Visa via email in JPEG or PDF format in attached files.
        </p>
        <p>
          The applicants applying for e-Visa services have to take cognizance of the fact that the maintenance of internal security is one of the most prioritized aspect of any country. Likewise the <span style={{ fontWeight: "bold" }}>Government of Cambodia</span> too keeps a close eye on the fact that no mischievous elements enter the Cambodia soil and disturb the peace and harmony here. The e-Visa application should furnish all the details required for visa approval in the format prescribed by the government.
        </p>
        <p>
          The applicants can go through our Document requirement section in order to make sure that they prepare all the documents in the prescribed format and file size. Apart from this the rules for e-Visa application are different for different countries. The residents of a select group of countries are eligible to apply for the e-Visa services. To check if one's country's name appears in the list of countries allowed to apply for e-Visa services or not he/she can visit our Instructions for applicant section. In this section the applicant can also check if he/she fulfills all the basic criteria required for getting e-Visa approval.
        </p>
        <h2>Terms and conditions related to Fee payment</h2>
        <p>
          Effective 17 October 2019, the Cambodia e-Visa Online fee has been changed as below. Please go through the new fee structure carefully before making your choice of Category of Cambodia e-Visa Application.
        </p>
        <ol>
          <li>
            <span style={{ fontWeight: "bold" }}>1.Visa T (30 Days Tourist e-Visa)</span>We charge an overall fee of USD 99 (Fees vary based on nationality) for a 30 Days Single Entry e-Visa This includes the visa fee and Processing fee which is charged by the Government of Cambodia. The Exchange rate applicable per passenger excluding interchange charge for credit/debit cards.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>2. Visa E (30 Days Business e-Visa) </span>We charge an overall fee of USD 149 (Fees vary based on nationality) for a 30 Days Single Entry e-Visa This includes the visa fee and Processing fee which is charged by the Government of Cambodia. The  Exchange rate applicable per passenger excluding interchange charge for credit/debit cards.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>3.</span>We reserve the right to modify our fee structure at any time. Changes will be effective immediately upon posting on the Website.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>4.</span>The Govt. The fee for Cambodia e-visa is USD 36 & USD 42 depending on the Visa type and nationality of the applicant. We advise you to check the government fee on the official website  <a href="www.evisa.gov.kh">www.evisa.gov.kh</a> and then pay on our website. Once the application is paid and processed, the fee is 100% non-refundable.
          </li>
        </ol>
        <h2>Expedite Service Fee</h2>
        <ul>
          <li><span style={{ fontWeight: "bold" }}>1. Urgent/Expedite Visa T (30 Days Tourist e-Visa)</span> We charge an overall fee of USD 149 for a 30 Days Single Entry Visa This includes the visa fee which is charged by the Government of Cambodia. Exchange rate applicable per passenger excluding interchange charge for credit/debit cards.</li>
          <li>
            <span style={{ fontWeight: "bold" }}>2. Urgent/Expedite Visa E (30 Days Business e-Visa)</span>We charge an overall fee of USD 199. This includes the visa fee which is charged by the Government of Cambodia. Exchange rate applicable per passenger excluding interchange charge for credit/debit cards.
          </li>
          <li></li>
          <li><span style={{ fontWeight: "bold" }}>3.</span>We reserve the right to modify our fee structure at any time. Changes will be effective immediately upon posting on the Website.
          </li>
          <li><span style={{ fontWeight: "bold" }}>4.</span>The Govt. The fee for Cambodia e-visa is USD 36 and USD 42 depending on the Visa type of the applicant. We advise you to check the government fee on the official website www.evisa.gov.kh and then pay on our website. Once the application is paid and processed, the fee is 100% non-refundable.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>5.</span>The e-Visa fees have to be submitted at the time of filling up of application forms. If the payment is not received the application gets automatically cancelled.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>6.</span>Our organization begins the process for e-Visa services as soon as it receives payment and required documents for the services. Once the process has started it cannot be terminated and no refunds either partial or full is given even if the applicant wants to withdraw from the e-Visa application process.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>7.</span>The applicants won't be reimbursed under any circumstances regardless of the fact that he/she gets visa approval or not. This is in accordance with the Government of Cambodia directives related to e-Visa.
          </li>
        </ul>
        <h2>Terms and conditions related to e-Visa application process:-</h2>
        <p>
          1. The e-Visa applicants have to provide all the relevant information essential for applying for e-Visa services including their names, postal addresses etc. as mentioned in their passports. Any anomalies will lead to the cancellation of their e-Visa applications.
        </p>
        <p>2. Our team of dedicated professionals try and make sure that the applications are submitted in the Government of Cambodia prescribed format. We might ask for additional information in order to speed up the e-Visa process. The applicants are requested to provide these details as and when required.</p>
        <p>3. The applicants should be careful to not use this website for creating any sort of rift between various stratus of the society.</p>
        <p>
          4. If any applicant is applying on behalf of an International tourist, he/she should have authorization from the traveler. Any error in filling up of details in the e-Visa application process has to be owed by the traveler and our organization takes no responsibility for it.
        </p>
        <p>
          5. The documents to be submitted online have to be in English language only. The format and size of the documents should be according to the officially prescribed format.
        </p>
        <p>
          6. The applicants should provide their correct email address at the time of application as the e-Visa is sent to the provided email address only in order to maintain privacy and control data theft.
        </p>
        <h2>
          Terms and conditions related to entry into Cambodia
        </h2>
        <p>1. Merely getting a e-Visa does not make an applicant eligible to gain access into the Cambodia Territory. The immigration officers at designated airports are the sole authorities controlling the influx of International tourists into Cambodia. They have the discretion of not allowing certain people to enter Cambodia. They are not entitled to give any specific reasons for denial of the visitor’s entry into Cambodia.</p>
        <p>
          2. The International tourists have to indulge in only those activities for which the e-Visa has been issued.
        </p>
        <p>
          3. The e-Visas have a validity of 30 days from the date of arrival of the traveler into Cambodia. The validity cannot be extended.
        </p>
        <p>
          4. The tourists who have applied for e-Visa do not gain access to the prohibited or sensitive areas in Cambodia. They are liable to be prosecuted if they are found indulging in it.
        </p>
        <p>
          5. The Tourist e-visa and Business e-visa are valid for Single entry.
        </p>
        <p>
          6. Entry into the Cambodia Territory is allowed only at the designated airports whose list has been provided in the Instructions for applicant section.
        </p>
        <p>
          7. The International travelers have to abide by the instructions of the local administration failing which he/she is liable to be deported.
        </p>
        <h2>Issuance Related Terms
        </h2>
        <p>
          Issuance of a e-Visa or approval of visa application does not in any way give the applicant a right to enter Cambodia. The entry is at the sole discretion of the Immigration officer at the Cambodia Airport who is a representative of the <spa style={{ fontWeight: "bold" }}>Government of Cambodia</spa>. In case of denial of visa or entry into Cambodia territory, cambodiaevisa.org shall not be held liable in any manner whatsoever.
        </p>
        <p>
          The issuance is subject to the rules of <span style={{ fontWeight: "bold" }}>Government of Cambodia</span> and they reserve the rights to make any changes in that. cambodiaevisa.org shall not be liable for any losses or damages, which the applicant may suffer arising from delay in processing or receiving of visa.
        </p>
        <p>
          cambodiaevisa.org is keen to take all reasonable measures to ensure that information provided by the Applicants will remain confidential. However cambodiaevisa.org shall not be liable for any unauthorized access by any means to that information. The applicant is requested to check the correctness of your visa the moment it is sent and in case of any discrepancy bring it to our notice immediately.
        </p>
        <p>

          The visa result shall be emailed 25 days prior to your expected arrival date in Cambodia if you have applied more than 30 days in advance. If your application is submitted within 60 days prior to your expected arrival date in Cambodia then your e-Visa result shall be emailed within 4 to 7 business days (Excluding Saturdays, Sundays and Cambodia Public Holidays) from the date of submission. If your travel is within 4-7 days then your e-Visa result shall be emailed in 3 Business Day. All the processing times are valid after receipt of the appropriate documents against each individual application. e-Visa Cambodia will not be responsible for any delay in the process due to non-availability of proper documents. Our delivery time starts after the application is complete with all the information and documents required to process the same. For these nationalities the delivery time is 5 business days in case of e-Visa processing and 10 business days in case of normal processing. Please send an email at info@cambodiaevisa.org. if you haven't received the delivery in the above mentioned time. Once the fee is paid against the application, it is non-refundable.
        </p>
        <h2>
          Disclaimer:
        </h2>
        <p>
          This organization does not take any responsibility for the e-Visa applications getting disqualified by the Cambodia government authorities. We will email the e-Visa cancellation proof to the applicant’s email address. Any legal dispute arising from the process of e-Visa application will be dealt with only at the Honorable High Court of New Delhi and nowhere else. We are a commercial website and charge service fees for our services. The applicants can directly visit the government of Cambobdia’s website and apply for e-Visa and avoid these additional charges. This organization can alter its terms and conditions whenever it deems fit. By using our website or by applying for e-Visa process through our website the applicant agrees to comply by the terms and conditions of this organization.
        </p>
        <h2>
          Cancellation and Refunds
        </h2>
        <p>
          As soon as the application is paid for, we will begin our service. That means that you cannot get your money back after we have started the service, no matter if your application is cancelled, refused or approved.
        </p>
        <h2>PAY BY PAYPAL</h2>
        <p>
          By using cambodiaevisa.org's pay by PayPal payment option ("PayPal"), you can purchase Services using PayPal. In connection therewith, you agree to allow PayPal to debit the full amount of your purchase from your PayPal account ("PayPal Account") or from credit card(s), bank account(s), or other allowed payment method(s) linked to your PayPal Account ("PayPal Funding Source").
        </p>
        <p>

          It is your responsibility to keep your PayPal Account and PayPal Funding Source current and funded, and your PayPal Account backed by a valid credit card. You acknowledge and agree that (i) PayPal reserves the right to decline a transaction for any reason (including, but not limited to, payments that fail to go through as a result of your PayPal Account or PayPal Funding Source no longer existing or not holding available/sufficient funds) and (ii) in such event, neither PayPal nor cambodiaevisa.org shall be liable to you or any third party regarding the same. If for any reason PayPal is unable to withdraw the full amount owed for your purchase, you agree that PayPal and cambodiaevisa.org may pursue all available lawful remedies in order to obtain payment. You agree that if the transaction is returned unpaid, you will pay a service charge of maximum amount allowed by law, which may be debited from your PayPal Account or PayPal Funding Source.
        </p>
        <p>
          By clicking the box labeled <span style={{ fontWeight: "bold" }}>"I agree" or "Process to Payment"</span> to the terms of the PayPal payment option, you authorize a debit of the full amount of your purchase from your PayPal Account or PayPal Funding Source.
        </p>
        <h2>AUTHORIZATION YOUR CREDIT CARD
        </h2>
        <p>
          To make sure card holder agreed to pay our services fee, may be our Risk Management team will request for fill-up Authorization form, need card holder signature on form and/or scan/take picture of real Credit Card with hidden 8 number of Credit Card. If you not comply with this requirement, services may be cancelled and any lost we will not responsibility or liabilities any loss or damage.
        </p>
      </div>
    </div>
  );
};

export default Terms;
